<template>
  <div class="cardAll-container">
    <CommonHeaderNav :isShowBeginBTn="isShowBeginBTn" @handleBeginStudy="handleBeginStudy"
      @handleStatusSelect="handleStatusSelect" @handleFlagSelect="handleFlagSelect" @handleSearch="handleSearch"
      @handleSortSelect="handleSortSelect" @handleTagSelect="handleTagSelect">
    </CommonHeaderNav>
    <NoticeBoard v-if="isShowNotice" @closeNoticeBoard="closeNoticeBoard"></NoticeBoard>
    <!-- <CardAllHeader @handleBeginStudy="handleBeginStudy" class="mg-l-24 mg-r-24 mg-b-10"
      v-show="!$store.state.isSelectAll">
      </CardAllHeader> -->
    <div class="scroll-area">
      <CardList :cardList="cardList" @toCardPreview="toCardPreview" @toEditorCard="toEditorCard"
        :isLoadData="isLoadData" @openChapterOption="openChapterOption" :showColumn="showColumn"
        :myChapterWidth="myChapterWidth" :page="page" @changeModelSuccess="changeModelSuccess">
      </CardList>
    </div>
    <study-viewer ref="myStudyView" v-if="isStudy && Object.keys(firstCardModel).length > 0"
      :studyCardData="studyCardData" @exitStudy="exitStudy" @editorCard="editorCard" @completedStudy="completedStudy"
      :isShowEditorCard="isShowEditorCard" :firstCardModel="firstCardModel" @openCreateCard="openCreateCard"
      @editorLinkCard="editorLinkCard">
    </study-viewer>
    <card-editor v-if="isShowEditorCard && Object.keys(firstCardModel).length > 0" @closeEditor="closeEditor"
      :isEditorCard="isEditorCard" :currentEditorCard="currentEditorCard" @saveEditSuccess="saveEditSuccess"
      :editorCardList="cardList" :currentCardIndex="currentCardIndex" @deleteCardSuccess="deleteCardSuccess"
      :firstCardModel="firstCardModel" @openImportData=openUpload :isShowSelectChpater="isShowSelectChpater"
      :isShowLeftOrRightBtn="isShowLeftOrRightBtn" :currentChapter="{}" :isEditLinkCard="isEditLinkCard"
      @openImportAnki="openImportAnki">
    </card-editor>
    <card-viewer v-if="isPreview && Object.keys(firstCardModel).length > 0" ref="myCardViewer"
      :studyCardData="studyCardData" @ClosePreview="ClosePreview" :cardList="cardList"
      :currentCardIndex="currentCardIndex" @previewToEditorCard="previewToEditorCard"
      @deleteCardSuccess="deleteCardSuccess" :isShowEditorCard="isShowEditorCard" :firstCardModel="firstCardModel"
      @openCreateCard="openCreateCard" @editorLinkCard="editorLinkCard"></card-viewer>
    <chapter-options ref="chapterOptions" @selectChapter="onSelectedChapter"></chapter-options>
    <import-data ref="importData"></import-data>
    <ImportAnki ref="importAnki"></ImportAnki>
  </div>
</template>
<script>
import CardList from '../components/CardList.vue'
import WaterfallCardList from '../components/WaterfallCardList.vue'
import CommonHeaderNav from '../components/common/CommonHeaderNav.vue'
import StudyViewer from '../components/StudyViewer.vue'
import CardEditor from '../components/CardEditor.vue'
import CardViewer from '../components/CardViewer.vue'
import ChapterOptions from '../components/ChapterOptions.vue'
import ImportData from '../components/ImportData.vue'
import CardAllHeader from '../components/chapterDetail/CardAllHeader.vue'
import BatchNav from '../components/common/BatchNav.vue'
import NoticeBoard from '../components/common/NoticeBoard.vue'
import ImportAnki from '../components/ImportAnki.vue'

import request from '../libs/api.request'
import { showScrollBar } from '../libs/util'
import { showMessage } from '../libs/message'
import { handleBatchCard } from '../libs/batch'
export default {
  // 一行显示的个数
  props: {
    showColumn: {
      type: Number,
    },
    myChapterWidth: {
      type: Number,
    }
  },
  components: {
    CardList,
    CommonHeaderNav,
    WaterfallCardList,
    StudyViewer,
    CardEditor,
    CardViewer,
    ChapterOptions,
    ImportData,
    CardAllHeader,
    BatchNav,
    NoticeBoard,
    NoticeBoard,
    ImportAnki
  },
  data() {
    return {
      hasMoreData: false,
      isLoadData: false,
      isStudy: false,
      isPreview: false,
      cardList: [],
      studyCardData: {},
      firstCardModel: {},
      currentTime: '',
      isShowEditorCard: false,
      isEditorCard: false,
      currentEditorCard: {},
      currentCardIndex: 0,
      isShowSelectChpater: false,
      isShowLeftOrRightBtn: false,
      chapterHeaderData: {},
      isShowBeginBTn: false,
      isEditLinkCard: false,//是否编辑的是双链卡牌
      // 查询条件
      page: 1,
      size: 25,
      status: 0,
      keyword: '',
      flags: '',
      tags: '',
      sort: 'ncrt',
      batchSelectedCard: [], // 批量选中的卡片
      isShowNotice: false
    }
  },
  methods: {
    changeModelSuccess() {
      console.log('cardALL');
      this.cardList = []
      this.page = 1
      this.getCardList()
    },
    // 编辑双链卡牌
    editorLinkCard(card, model) {
      this.isEditLinkCard = true
      this.firstCardModel = model
      this.isShowSelectChpater = true
      this.isShowEditorCard = true
      this.isEditorCard = true
      this.currentEditorCard = card
    },
    closeNoticeBoard() {
      this.isShowNotice = false
    },
    openImportAnki() {
      this.closeEditor()
      let t = setTimeout(() => {
        this.$refs.importAnki.open()
        clearTimeout(t)
      }, 600)
    },
    openChapterOption(batchSelectedCard) {
      // 批量选中的卡牌
      this.batchSelectedCard = batchSelectedCard
      this.$refs.chapterOptions.open();
    },
    // 批量移动
    onSelectedChapter(chapter) {
      handleBatchCard(this, `将所选卡牌移动到【 ${chapter.name} 】?`, '移动', this.$refs.chapterOptions, {
        targetChapterId: chapter.chapterId,
        cardIds: this.batchSelectedCard
      },
        '/card/batchMove')
    },
    //从预览页到编辑卡牌页
    previewToEditorCard(card, index, model) {
      this.isShowEditorCard = true
      this.isEditorCard = true
      this.currentEditorCard = card
      this.currentCardIndex = index
      this.firstCardModel = model
      this.isShowSelectChpater = true
    },
    // 关闭预览
    ClosePreview(isHasDelete) {
      this.isPreview = false
      // 删除过的话，则要更新分类的数据
      if (isHasDelete) {
      }
      this.firstCardModel = {}
      showScrollBar()
    },
    // 卡牌预览
    toCardPreview(card, index) {
      this.getCardModel(card.modelId)
      this.isPreview = true
      this.studyCardData = card // 预览的卡牌
      this.currentCardIndex = index
    },
    //首页开始学习
    handleBeginStudy() {
      this.$myLoading.start({ bgcColor: 'rgba(247, 247, 247, .9)' })
      request.request(
        {
          url: '/chapter/study',
          method: 'post',
          data: {
            studyChapterId: 0,
          }
        }).then(res => {
          if (Object.prototype.hasOwnProperty.call(res, 'card')) {
            if (res.card === null) {
              this.$myLoading.finishProgress()
              this.$myLoading.finish()
              return showMessage({ message: '当前没有待学习的卡牌，有疑问请联系客服', type: 'warning' })
            } else {
              this.getCardModel(res.card.modelId)
              this.$store.commit('setModelList', res.modelList)
              this.studyCardData = res
              this.currentTime = new Date().getTime()
              this.$myLoading.finishProgress()
              this.$myLoading.finish()
              this.isStudy = true
            }
          } else {
            // 后端有异常
            this.$myLoading.finishProgress()
            this.$myLoading.finish()
            showMessage({ message: '数据异常，请联系客服', type: 'warning' })
          }
        }).catch(err => {
          showMessage({ message: err.message, type: 'error' })
          this.$myLoading.finishProgress()
          this.$myLoading.finish()
        })
    },
    getCardModel(modelId) {
      request.request({
        url: "model/detail",
        params: {
          modelId: modelId
        }
      }).then(res => {
        this.firstCardModel = res
      }).catch(e => {
        showMessage({ message: e.message, type: 'error' })
      })
    },
    // 卡牌列表中点击编辑卡牌的菜单按钮
    toEditorCard(card, index) {
      this.getCardModel(card.modelId)
      this.isShowLeftOrRightBtn = true
      this.currentCardIndex = index
      this.isShowSelectChpater = true
      this.isShowEditorCard = true
      this.isEditorCard = true
      this.currentEditorCard = card
    },
    // 从普通的编辑或者从学习处编辑卡牌
    editorCard(card, model) {
      console.log(card, model);
      if (!this.isStudy) {
        console.log('非学习进入编辑');
        this.getCardModel(card.modelId)
        this.isShowSelectChpater = true
      } else {
        this.firstCardModel = model
      }
      this.isShowEditorCard = true
      this.isEditorCard = true
      this.currentEditorCard = card
    },
    completedStudy() {
      this.$router.go(0)
    },
    openCreateCard(curCard) {
      console.log(curCard);
      this.currentEditorCard = curCard
      if (this.isPreview || this.isStudy) {
        this.isShowSelectChpater = true
      }
      this.isShowEditorCard = true
      if (Object.keys(this.$store.state.selectedModel).length > 0) {
        this.firstCardModel = this.$store.state.selectedModel
      } else {
        this.firstCardModel = this.$store.state.modelList[0]
      }
    },
    saveEditSuccess(card, index) {
      if (this.isStudy) {
        if (this.isEditorCard) {
          this.$refs.myStudyView.rendHtml()
        } else {
          console.log('学习时新增');
          this.cardList.unshift(card)
        }
      } else {
        if (this.isPreview && this.isEditorCard) {
          this.$refs.myCardViewer.rendHtml()
        }
        if (this.isEditorCard) {
          // 如果是编辑某个卡牌的时候
          console.log('这里是替换之前被修改的卡');
          this.cardList.splice(index, 1, card)
        } else {
          console.log('预览的时候，新增卡牌');
          this.cardList.unshift(card)
        }
      }
      this.closeEditor()
    },
    closeEditor(hasSave, hasDeleteCard, hasMove) {
      if (hasMove) {
        location.reload()
        return
      }
      this.isEditorCard = false
      this.isShowSelectChpater = false
      this.isShowEditorCard = false
      this.isEditLinkCard = false
      if (this.isPreview || this.isStudy) {
      } else {
        this.firstCardModel = {}
        showScrollBar()
      }
      if (hasDeleteCard) {
        // 如果删除过卡牌则需要重新获取卡牌分类的数据以及学习的数据

      }
    },
    deleteCardSuccess(index) {
      this.cardList.splice(index, 1)
    },
    openUpload() {
      if (this.isShowEditorCard || this.isPreview || this.isStudy) {
        this.isShowEditorCard = false
        this.isPreview = false
        this.isStudy = false
      }
      setTimeout(() => {
        this.$refs.importData.open();
      }, 200)
    },
    exitStudy(hasChange) {
      this.isPreview = false
      this.isStudy = false
      if (hasChange) {
        // 如果点击了学习则要刷新一下
        this.$router.go(0)
      }
      this.firstCardModel = {}
      showScrollBar()
    },
    handleTagSelect(tags) {
      this.tags = tags
      this.page = 1
      this.getCardList()
    },
    handleSortSelect(sortId) {
      document.querySelector('.customPane').scrollTop = 0
      this.sort = sortId
      this.page = 1
      this.getCardList()
    },
    handleSearch(keyword) {
      this.keyword = keyword
      this.page = 1
      this.getCardList()
    },
    handleFlagSelect(flag) {
      this.flags = flag
      this.page = 1
      this.getCardList()
    },
    handleStatusSelect(status) {
      if (status.length === 0) {
        this.status = 0
      } else {
        this.status = status
      }
      this.page = 1
      this.getCardList()
    },
    loadMoreData() {
      if (this.hasMoreData || this.isLoadData) {
        return
      }
      this.page++
      this.getCardList()
    },
    async getCardList() {
      this.isLoadData = true
      const res = await request.request({
        url: '/card/all',
        params: {
          page: this.page,
          size: this.size,
          status: this.status,
          flags: this.flags,
          keyword: this.keyword,
          tags: this.tags,
          sort: this.sort
        }
      })
      this.isLoadData = false
      this.hasMoreData = res.list.length < this.size
      if (this.page === 1) {
        this.cardList = res.list
      } else {
        this.cardList = this.cardList.concat(res.list)
      }
    },
    // 获取文档高度
    getScrollHeight() {
      let scrollHeight = document.querySelector('.scroll-area').scrollHeight
      return scrollHeight
    },
    // 窗口总高度
    getClientHeight() {
      let listClientHeight = document.body.clientHeight || document.documentElement.clientHeight
      return listClientHeight
    },
    //滚动的高度
    getScrollTop() {
      let scrollTop = document.querySelector('.customPane').scrollTop
      return scrollTop
    },
    handleScroll() {
      if (this.$route.name === 'CardAll') {
        if (this.getClientHeight() + this.getScrollTop() >= this.getScrollHeight() - 3000) {
          this.loadMoreData()
        }
        if (this.getScrollTop() > 170) {
          this.isShowBeginBTn = true
        } else {
          if (this.isShowBeginBTn === false) {
            return
          }
          this.isShowBeginBTn = false
        }
      }
    }
  },
  created() {
    if (localStorage.getItem('sort')) {
      this.sort = localStorage.getItem('sort')
    }
    // this.$myLoading.start()
    this.getCardList()
    if (!this.$store.state.totalChapterData.notice) {
      this.isShowNotice = false
      return
    }
    if (localStorage.getItem('noticeId')) {
      const noticeId = JSON.parse(localStorage.getItem('noticeId')) * 1
      if (noticeId >= this.$store.state.totalChapterData.notice.id) {
        this.isShowNotice = false
      } else {
        this.isShowNotice = true
      }
    } else {
      this.isShowNotice = true
    }
    // this.$myLoading.finishProgress()
    // this.$myLoading.finish()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  activated() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  deactivated() {
    window.removeEventListener('scroll', this.handleScroll, true)
  }
}
</script>

<style lang="scss" scoped>
.cardAll-container {
  position: relative;
  // min-height: 100%;

  .scroll-area {
    height: 100%;
    // overflow-y: scroll;
  }

  .my-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
  }
}
</style>
