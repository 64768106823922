<template>
  <div class="chapter-header">
    <img src="../../assets/images/card_all_default_cover.png" class="chapter-img">
    <div class="chapter-content">
      <div class="chapter-title">今日待学</div>
      <div class="chapter-study-data">
        <div class="chapter-study-data-text">新卡 {{ $store.state.totalChapterData.totalNewCardNum }}</div>
        <div class="chapter-study-data-line"></div>
        <div class="chapter-study-data-text">复习 {{ $store.state.totalChapterData.totalRevCardNum }}</div>
        <div class="chapter-study-data-line"></div>
        <div class="chapter-study-data-text">学时 {{ formatTime($store.state.totalChapterData.totalStudyCardTime) }}</div>
      </div>
      <div class="chapter-progress">
        <div class="progress">
          <el-progress :show-text="false" class="my-progress" type="circle"
            :color="'#FF8800'" :stroke-width="3"
            :width="20"
            :percentage="studyPercentage()">
          </el-progress>
          <div class="progress-text" :style="{ color: '#FF8800' }" v-if="$store.state.totalChapterData.totalNewCardNum!== 0">
            已学
          {{ currentProgress()}}/{{ $store.state.totalChapterData.totalCardNum }}
          </div>
          <div class="progress-text" :style="{ color: '#FF8800' }" v-else>
            已掌握
          {{ currentProgress()}}/{{ $store.state.totalChapterData.totalCardNum }}
          </div>
        </div>
        <el-button class="w-130 h-40 bdr-8" type="primary" @click="beginStudyClick"
          :disabled="$store.state.totalChapterData.todayNewCardNum + $store.state.totalChapterData.todayRevCardNum === 0"
          :style="{ background: btnBgc, borderColor: btnBgc }">
          开始记忆 {{studyNum}}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { formatTime } from '../../libs/analyse'
import { mapGetters } from 'vuex'
export default {
  methods: {
    beginStudyClick() {
      this.$emit('handleBeginStudy')
    }
  },
  computed: {
    studyPercentage() {
      return () => {
        if(this.$store.state.totalChapterData.totalNewCardNum === 0) {
          return Number(((this.$store.state.totalChapterData.handledCardNum /this.$store.state.totalChapterData.totalCardNum)*100))
        }else {
          return Number((((this.$store.state.totalChapterData.totalCardNum - this.$store.state.totalChapterData.totalNewCardNum)/this.$store.state.totalChapterData.totalCardNum)*100))
        }
      }
    },
    currentProgress(){
      return () => {
        if(this.$store.state.totalChapterData.totalNewCardNum === 0) {
          return this.$store.state.totalChapterData.handledCardNum
        }else {
          return this.$store.state.totalChapterData.totalCardNum - this.$store.state.totalChapterData.totalNewCardNum
        }
      }
    },
    studyNum(){
      return this.$store.state.totalChapterData.todayRevCardNum + this.$store.state.totalChapterData.todayNewCardNum 
    },
    btnBgc() {
      return this.$store.state.totalChapterData.todayRevCardNum + this.$store.state.totalChapterData.todayNewCardNum === 0 ? '#ccc' : ''
    },
    formatTime() {
      return time => {
        return formatTime(time)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/css/mixin.scss';

.chapter-header {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  background-color: #fff;
  padding: 16px;
  display: flex;
  height: 166px;

  .chapter-img {
    width: 100px;
    height: 134px;
    border-radius: 6px;
    object-fit: cover;
  }

  .chapter-content {
    flex: 1;
    margin-left: 16px;

    .chapter-title {
      font-size: 20px;
      font-weight: 500;
      color: #000000;
      line-height: 22px;
      margin-top: 5px;
      @include clamp(1)
    }

    .chapter-study-data {
      display: flex;
      align-items: center;
      margin-top: 24px;

      .chapter-study-data-text {
        font-size: 14px;
        font-weight: 500;
        color: #202020;
        line-height: 20px;
      }

      .chapter-study-data-line {
        width: 2px;
        height: 14px;
        background-color: rgba(32, 32, 32, 0.5);
        opacity: .4;
        border-radius: 1px;
        margin: 0 10px;
      }
    }

    .chapter-progress {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;

      .progress {
        display: flex;
        align-items: center;
      }

      .progress-text {
        font-size: 14px;
        font-weight: 500;
        color: #FFA800;
        line-height: 20px;
        margin-left: 6px;
      }
    }
  }
}
</style>

<style>
.my-progress .el-progress-circle__track {
  stroke: rgba(255, 183, 0, 0.3);
}
</style>