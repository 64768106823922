<template>
  <!-- 公告栏 -->
  <div class="notice-board-box">
    <div class="notice-content-box" @click="openLink">
      <div class="content">
        <svg-icon iconClass="notice_sound" class="w-24 h-24 mg-r-10"></svg-icon>
        <div class="text">{{ $store.state.totalChapterData.notice.title }}</div>
      </div>
      <svg-icon iconClass="notice_closed" class="w-20 h-20 cursor-pointer" @click.stop="closeNotice"></svg-icon>
    </div>
  </div>
</template>
<script>
import { copyData } from '../../libs/myClipboard'

export default {
  methods: {
    openLink() {
      if (this.$store.state.totalChapterData.notice && this.$store.state.totalChapterData.notice.openType === 'internal') {
        location.href = this.$store.state.totalChapterData.notice.url
      } else {
        if (localStorage.getItem('useOrigin')) {
          copyData(this.$store.state.totalChapterData.notice.url, '链接已复制！请前往浏览器中打开')
        } else {
          window.open(this.$store.state.totalChapterData.notice.url)
        }
      }
    },
    closeNotice() {
      localStorage.setItem('noticeId', JSON.stringify(this.$store.state.totalChapterData.notice.id))
      this.$emit('closeNoticeBoard')
    }
  },
}
</script>
<style lang="scss" scoped>
.notice-board-box {
  padding: 0 24px;
  height: 42px;
  margin-bottom: 6px;

  .notice-content-box {
    height: 100%;
    background: linear-gradient(90deg, #FFF8EB 0%, #FFEBC5 100%);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px 0 20px;

    &:hover {
      cursor: pointer;
    }

    .content {
      display: flex;
      align-items: center;

      .text {
        font-size: 14px;
        font-weight: 500;
        color: #FFA800;
      }
    }
  }

}
</style>